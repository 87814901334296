@keyframes button-ping {
  0% {
    opacity: 0.8;
    transform: scale(1);
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 0;
    transform: scaleX(1.1) scaleY(1.35);
  }
}
