.psc-link {
  @apply font-medium text-blue-800 hover:text-blue-700 focus:ring-1 focus:outline-none focus:ring-blue-700 focus:ring-offset-2 hover:underline cursor-pointer;
}

.psc-link-disabled {
  @apply font-medium text-gray-800 focus:ring-1 focus:outline-none focus:ring-blue-700 focus:ring-offset-2 cursor-not-allowed;
}

.psc-link-danger {
  @apply font-medium text-red-800 hover:text-red-700 focus:ring-1 focus:outline-none focus:ring-red-700 focus:ring-offset-2 hover:underline cursor-pointer;
}

.psc-link-no-underline {
  @apply font-medium text-blue-800 hover:text-blue-700 focus:ring-1 focus:outline-none focus:ring-blue-700 focus:ring-offset-2 cursor-pointer;
}
